body {
  background-color: #EAEAEA;
}

.survey {
  padding: 20px 20px 40px 20px;
  max-width: 1280px;
  margin: 0 auto;

  .video-container {
    position: relative;

    .controls {
      position: absolute;
      text-align: center;
      width: 100%;
      bottom: 20px;
      margin-left: -54px;
      left: 50%;
      width: 108px;
      transition: opacity 0.5s ease-in-out;

      &.hidden {
        opacity: 0;
      }

      button {
        color: white;
        background-color: #0972d3;
        border: none;
        font-size: 22px;
        margin: 0 5px;
        padding: 0;
        width: 44px;
        height: 44px;
        border-radius: 22px;
      }
    }
  }
}