/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
section.awsui_appbar_hyvsj_1u1kg_93:not(#\9) {
  align-items: center;
  box-sizing: border-box;
  display: grid;
  grid-column: 3;
  grid-row: 2;
  grid-template-columns: auto minmax(0, 1fr) auto;
  /* 
  Because the Breadcrumbs component cannot be styled directly it is 
  wrapped in a generic element that will apply style adjustments as needed.
  */
  /*
  In large viewports the buttons that trigger the Navigation and Tools 
  drawers from the AppBar are suppressed and replaced by the buttons 
  that render in the Navigation and Tools components. Additionally, 
  the content wrapper around the breadcrumbs component has variable 
  top and bottom padding based on whether or not the Notifications 
  and Header components exist.
  */
  /*
  In mobile viewports the AppBar becomes sticky and is repositioned to
  span the entire column definition of the Layout grid.
  */
}
section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_appbar-nav_hyvsj_1u1kg_117:not(#\9) {
  grid-column: 1;
  margin-right: var(--space-m-17eucw, 16px);
}
section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121:not(#\9) {
  grid-column: 2;
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
}
section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_appbar-tools_hyvsj_1u1kg_125:not(#\9),
section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_drawers-mobile-triggers-container_hyvsj_1u1kg_126:not(#\9) {
  grid-column: 3;
  margin-left: var(--space-m-17eucw, 16px);
}
@media (min-width: 689px) {
  section.awsui_appbar_hyvsj_1u1kg_93:not(#\9) {
    /*
    If Breadcrumbs do not exist then the AppBar should be suppressed
    because in large viewports the triggers are rendered
    in the Navigation and Tools components.
    */
    /*
    If there is no Notifications content then the AppBar should 
    appear to be the first rendered content even though the 
    Notifications DOM node will still exist. The top padding 
    should vertically align it with the Navigation and Tools circular buttons.
    */
    /*
    If there is Notifications content then the AppBar should have 
    minimal top padding in addition to the bottom margin on the 
    Notifications component.
    */
    /*
    If the Header does not exist then the AppBar is the last 
    rendered content before the Main component. Additional 
    bottom padding is added to create space between the 
    Breadcrumbs and Main components that expands the height 
    of the dark header.
    */
    /*
    Table and cards content types implement a custom dark header. 
    Minimal bottom padding should be on the breadcrumbs to
    reduce the the vertical distance from the main content.
    */
    /*
    If a child component tells the AppLayout to have a sticky 
    background (typically because of a stickyHeader with a 
    full-page variant) the z-index of the breadcrumbs needs to 
    be lifted so it doesn't render behind the background element.
    */
  }
  section.awsui_appbar_hyvsj_1u1kg_93:not(#\9):not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167) {
    display: none;
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_appbar-nav_hyvsj_1u1kg_117:not(#\9),
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_appbar-tools_hyvsj_1u1kg_125:not(#\9),
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_drawers-mobile-triggers-container_hyvsj_1u1kg_126:not(#\9) {
    display: none;
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121:not(#\9):not(.awsui_has-notifications-content_hyvsj_1u1kg_175) {
    padding-top: var(--space-scaled-m-pv0fmt, 16px);
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121.awsui_has-notifications-content_hyvsj_1u1kg_175:not(#\9) {
    padding-top: var(--space-scaled-2x-s-9svxjy, 12px);
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121:not(#\9):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181),
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121:not(#\9):not(.awsui_has-header_hyvsj_1u1kg_181).awsui_content-type-wizard_hyvsj_1u1kg_182 {
    padding-bottom: var(--space-m-17eucw, 16px);
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121.awsui_content-type-cards_hyvsj_1u1kg_185:not(#\9),
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121.awsui_content-type-table_hyvsj_1u1kg_186:not(#\9) {
    padding-bottom: var(--space-xxs-ynfts5, 4px);
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121.awsui_has-sticky-background_hyvsj_1u1kg_189:not(#\9) {
    z-index: 799;
  }
}
@media (max-width: 688px) {
  section.awsui_appbar_hyvsj_1u1kg_93:not(#\9) {
    background-color: var(--color-background-home-header-mpq08f, #000716);
    border-bottom: 1px solid var(--color-border-divider-default-7s2wjw, #e9ebed);
    box-shadow: var(--shadow-panel-toggle-3fk0ch, 0px 6px 12px 1px rgba(0, 7, 22, 0.12));
    grid-column: 1/span 5;
    grid-row: 1;
    height: calc(2 * var(--space-m-17eucw, 16px) + var(--space-scaled-xs-6859qs, 8px));
    padding: 0 var(--space-m-17eucw, 16px);
    position: sticky;
    top: var(--awsui-offset-top-3j0evk);
    z-index: 1000;
    /*
    Remove any existing top and bottom padding from the Breadcrumbs since
    they are now sticky positioned in the viewport with the AppBar.
    */
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_appbar-nav_hyvsj_1u1kg_117:not(#\9),
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_appbar-tools_hyvsj_1u1kg_125:not(#\9),
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_drawers-mobile-triggers-container_hyvsj_1u1kg_126:not(#\9) {
    display: block;
  }
  section.awsui_appbar_hyvsj_1u1kg_93 > .awsui_breadcrumbs_hyvsj_1u1kg_121:not(#\9) {
    padding: 0;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
div.awsui_background_hyvsj_1u1kg_224:not(#\9) {
  display: contents;
}
div.awsui_background_hyvsj_1u1kg_224 > .awsui_notifications-appbar-header_hyvsj_1u1kg_227:not(#\9) {
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
  grid-column: 1/span 5;
  grid-row: 1/span 3;
  color: var(--color-text-body-default-ajf1h5, #000716);
  /*
  The cards and table content types have a sticky dark header that is 
  offset beneath the sticky notifications if they exist. If the background 
  is not sticky then it will scroll out of the viewport leaving a white space
  behind the notifications above the content dark header.
  */
}
@media (min-width: 689px) {
  div.awsui_background_hyvsj_1u1kg_224 > .awsui_notifications-appbar-header_hyvsj_1u1kg_227.awsui_has-sticky-background_hyvsj_1u1kg_189.awsui_has-notifications-content_hyvsj_1u1kg_175.awsui_sticky-notifications_hyvsj_1u1kg_240:not(#\9) {
    position: sticky;
    top: var(--awsui-offset-top-3j0evk);
    z-index: 799;
  }
}
div.awsui_background_hyvsj_1u1kg_224 > .awsui_overlap_hyvsj_1u1kg_246:not(#\9) {
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
  grid-column: 1/span 5;
  grid-row: 4;
  color: var(--color-text-body-default-ajf1h5, #000716);
  /*
  The cards and table content types can have sticky header content that 
  will retain position on viewport scroll. The overlap background needs to 
  be sticky in desktop viewports to prevent light background gutters with 
  the high contrast header background only in the content area.
  */
}
@media (min-width: 689px) {
  div.awsui_background_hyvsj_1u1kg_224 > .awsui_overlap_hyvsj_1u1kg_246.awsui_has-sticky-background_hyvsj_1u1kg_189:not(#\9) {
    position: sticky;
    top: var(--awsui-offset-top-with-notifications-3j0evk);
    z-index: 799;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_drawers-container_hyvsj_1u1kg_270:not(#\9) {
  background-color: transparent;
  display: flex;
  grid-column: 5;
  grid-row: 1/span 5;
  height: var(--awsui-content-height-3j0evk);
  pointer-events: none;
  position: sticky;
  top: var(--awsui-offset-top-3j0evk);
  z-index: 830;
  transition: background-color var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
.awsui_drawers-container_hyvsj_1u1kg_270.awsui_has-open-drawer_hyvsj_1u1kg_282:not(#\9) {
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  box-shadow: var(--shadow-panel-gp3bf2, 0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawers-container_hyvsj_1u1kg_270:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawers-container_hyvsj_1u1kg_270:not(#\9), .awsui-mode-entering .awsui_drawers-container_hyvsj_1u1kg_270:not(#\9) {
  animation: none;
  transition: none;
}
@media (min-width: 689px) {
  .awsui_drawers-container_hyvsj_1u1kg_270:not(#\9) {
    /* stylelint-disable scss/operator-no-newline-after */
    max-width: calc(var(--awsui-layout-width-3j0evk) - var(--awsui-main-offset-left-3j0evk) - var(--awsui-default-min-content-width-3j0evk) - var(--awsui-content-gap-right-3j0evk));
    /* stylelint-enable scss/operator-no-newline-after */
  }
}
@media (max-width: 688px) {
  .awsui_drawers-container_hyvsj_1u1kg_270:not(#\9) {
    position: fixed;
    right: 0;
    top: var(--awsui-offset-top-3j0evk);
    z-index: 1001;
  }
}

.awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background-color: transparent;
  box-sizing: border-box;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
}
.awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330).awsui_has-open-drawer_hyvsj_1u1kg_282 {
  width: 0;
}
.awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312.awsui_has-multiple-triggers_hyvsj_1u1kg_330.awsui_has-open-drawer_hyvsj_1u1kg_282:not(#\9) {
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  transition: background-color var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312.awsui_has-multiple-triggers_hyvsj_1u1kg_330.awsui_has-open-drawer_hyvsj_1u1kg_282:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312.awsui_has-multiple-triggers_hyvsj_1u1kg_330.awsui_has-open-drawer_hyvsj_1u1kg_282:not(#\9), .awsui-mode-entering .awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312.awsui_has-multiple-triggers_hyvsj_1u1kg_330.awsui_has-open-drawer_hyvsj_1u1kg_282:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282) {
  width: calc(var(--space-layout-toggle-padding-axl9jr, 20px) * 2 + var(--space-layout-toggle-diameter-hzvqtj, 40px));
  transition: width var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282), .awsui-mode-entering .awsui_drawers-desktop-triggers-container_hyvsj_1u1kg_312:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282) {
  animation: none;
  transition: none;
}

.awsui_drawers-trigger-content_hyvsj_1u1kg_362:not(#\9) {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: var(--space-xs-rsr2qu, 8px);
  padding-top: var(--space-scaled-s-cu1hzn, 12px);
  width: calc(var(--space-layout-toggle-padding-axl9jr, 20px) * 2 + var(--space-layout-toggle-diameter-hzvqtj, 40px));
}
.awsui_drawers-trigger-content_hyvsj_1u1kg_362:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330).awsui_has-open-drawer_hyvsj_1u1kg_282 {
  opacity: 0;
}
.awsui_drawers-trigger-content_hyvsj_1u1kg_362:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282) {
  opacity: 1;
  transition: opacity var(--motion-duration-refresh-only-medium-5flen2, 165ms);
  transition-delay: calc(var(--motion-duration-refresh-only-fast-addxjd, 115ms) / 1.5);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawers-trigger-content_hyvsj_1u1kg_362:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawers-trigger-content_hyvsj_1u1kg_362:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282), .awsui-mode-entering .awsui_drawers-trigger-content_hyvsj_1u1kg_362:not(#\9):not(.awsui_has-multiple-triggers_hyvsj_1u1kg_330):not(.awsui_has-open-drawer_hyvsj_1u1kg_282) {
  animation: none;
  transition: none;
}

.awsui_drawer_hyvsj_1u1kg_126:not(#\9) {
  --awsui-active-drawer-width-3j0evk: 290px;
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  border-color: transparent;
  display: grid;
  flex-shrink: 0;
  grid-template-columns: 1fr auto var(--space-m-17eucw, 16px);
  grid-template-rows: var(--size-vertical-panel-icon-offset-ti40n4, 15px) auto 1fr;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  pointer-events: auto;
  word-wrap: break-word;
  transition: var(--motion-duration-refresh-only-fast-addxjd, 115ms);
  transition-property: border-color, opacity, width;
}
.awsui_drawer_hyvsj_1u1kg_126 > .awsui_drawer-close-button_hyvsj_1u1kg_406:not(#\9) {
  grid-column: 2;
  grid-row: 2;
}
.awsui_drawer_hyvsj_1u1kg_126 > .awsui_drawer-content_hyvsj_1u1kg_410:not(#\9) {
  grid-column: 1/span 3;
  grid-row: 1/span 3;
  width: var(--awsui-active-drawer-width-3j0evk);
}
.awsui_drawer_hyvsj_1u1kg_126:not(#\9):not(.awsui_is-drawer-open_hyvsj_1u1kg_415) {
  opacity: 0;
  width: 0;
}
.awsui_drawer_hyvsj_1u1kg_126.awsui_is-drawer-open_hyvsj_1u1kg_415:not(#\9) {
  border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
  opacity: 1;
  width: var(--awsui-active-drawer-width-3j0evk);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_drawer_hyvsj_1u1kg_126:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_drawer_hyvsj_1u1kg_126:not(#\9), .awsui-mode-entering .awsui_drawer_hyvsj_1u1kg_126:not(#\9) {
  animation: none;
  transition: none;
}
@media (min-width: 2541px) {
  .awsui_drawer_hyvsj_1u1kg_126.awsui_is-drawer-open_hyvsj_1u1kg_415:not(#\9) {
    --awsui-active-drawer-width-3j0evk: 320px;
  }
}
@media (max-width: 688px) {
  .awsui_drawer_hyvsj_1u1kg_126.awsui_is-drawer-open_hyvsj_1u1kg_415:not(#\9) {
    --awsui-active-drawer-width-3j0evk: 100vw;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
header.awsui_content_hyvsj_1u1kg_182:not(#\9) {
  grid-column: 3;
  grid-row: 3;
  padding-bottom: var(--space-content-header-padding-bottom-nvqytg, 24px);
}
@media (min-width: 689px) {
  header.awsui_content_hyvsj_1u1kg_182:not(#\9) {
    /*
    If Notifications exist and Breadcrumbs do not then Notifications 
    is the previous adjacent sibling to the Header. Add minimal top padding 
    in addition to the bottom padding on the Notifications.
    */
    /*
    If Breadcrumbs exists it is necessarily the previous adjacent 
    sibling and the Header should have no padding. Add minimal top 
    padding for vertical space between the Breadcrumbs and Header.
    */
    /*
    If Notifications and Breadcrumbs do not exist then the Header 
    is the first rendered center content. The top padding should 
    vertically align the Header with the Navigation and Tools 
    circular buttons.
    */
  }
  header.awsui_content_hyvsj_1u1kg_182.awsui_has-notifications-content_hyvsj_1u1kg_175:not(#\9):not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167) {
    padding-top: var(--space-scaled-xs-6859qs, 8px);
  }
  header.awsui_content_hyvsj_1u1kg_182.awsui_has-breadcrumbs_hyvsj_1u1kg_167:not(#\9) {
    padding-top: var(--space-xxs-ynfts5, 4px);
  }
  header.awsui_content_hyvsj_1u1kg_182:not(#\9):not(.awsui_has-notifications-content_hyvsj_1u1kg_175):not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167) {
    padding-top: var(--space-scaled-xs-6859qs, 8px);
  }
}
@media (max-width: 688px) {
  header.awsui_content_hyvsj_1u1kg_182:not(#\9) {
    padding: var(--space-m-17eucw, 16px) 0 var(--space-content-header-padding-bottom-nvqytg, 24px);
    /*
    Breadcrumbs has moved to first position and sticky so Notifications 
    are now the previous adjacent sibling if they exist.
    */
  }
  header.awsui_content_hyvsj_1u1kg_182.awsui_has-notifications-content_hyvsj_1u1kg_175:not(#\9) {
    padding-top: var(--space-scaled-xs-6859qs, 8px);
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
  The first and last column definitions have two responsibilities.
  If Navigation and/or Tools exist then that will determine the width of
  the first and last columns, respectively, and the content in these drawers
  will create horizontal space from the center column content. However, if the
  navigationHide and/or toolsHide properties have been set then the first
  and last column width will default to the minimum content gap properties to
  create the necessary visual space around the main content.

  The minimum content width property is set to zero and applied under all
  circumstances regardless of whether the minContentWidth property is
  explicitly set in script.
  */
.awsui_layout_hyvsj_1u1kg_517:not(#\9) {
  --awsui-content-gap-left-3j0evk: 0px;
  --awsui-content-gap-right-3j0evk: 0px;
  --awsui-content-height-3j0evk: calc(100vh - var(--awsui-header-height-3j0evk) - var(--awsui-footer-height-3j0evk));
  --awsui-default-max-content-width-3j0evk: 1280px;
  --awsui-default-min-content-width-3j0evk: 0px;
  --awsui-footer-height-3j0evk: 0px;
  --awsui-header-height-3j0evk: 0px;
  --awsui-layout-width-3j0evk: 0px;
  --awsui-main-offset-left-3j0evk: 0px;
  --awsui-max-content-width-3j0evk: 0px;
  --awsui-min-content-width-3j0evk: 280px;
  --awsui-notifications-height-3j0evk: 0px;
  --awsui-offset-top-3j0evk: 0px;
  --awsui-offset-top-with-notifications-3j0evk: 0px;
  --awsui-overlap-height-3j0evk: var(--space-dark-header-overlap-distance-hfejul, 36px);
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
  display: grid;
  grid-template-rows: repeat(3, auto) var(--awsui-overlap-height-3j0evk) 1fr;
  grid-template-columns: min-content minmax(var(--awsui-content-gap-left-3j0evk), 1fr) minmax(var(--awsui-default-min-content-width-3j0evk), var(--awsui-default-max-content-width-3j0evk)) minmax(var(--awsui-content-gap-right-3j0evk), 1fr) min-content;
  position: relative;
  color: var(--color-text-body-default-ajf1h5, #000716);
  /*
  Only use the max content width custom property for the middle column definition
  if the maxContentWidth property has been explicitly set to a non-zero value and
  subsequently set the has-max-content-width data attribute to true.
  */
  /*
  Warning! This is a hack! We are implementing a short term fix to prevent the
  tools drawer from potentially getting pushed off screen. Currently there is no
  protection against a navigationWidth, minContentWidth, and toolsWidth that are
  in excess ove the available space in the viewport. To accomodate this we remove
  the minContentWidth and set it to zero in small viewports and below until the
  isMobile breakpoint sets the drawers to overlay at 100vw.
  */
  /*
  Override the dark header overlap height property if isOverlapDisabled is true.
  The is computed state from props that can be found in the AppLayout context.
  */
  /*
  If disableBodyScroll is false (this is the overwhelming majority
  of use cases and the default scenario) then the scrollable element
  will be the document body which is a parent of the AppLayout component.
  The Layout should have a minimum but not fixed content height set to the
  viewport height less the header and footer height set by the resize
  observers in the component. The offset top value for components
  with position: sticky (AppBar, Navigation, Notifications, Tools) will
  be set to the header height value. The offset top value for AppLayout
  contentMain children with position: sticky will be the combined value of the
  header and notifications height in addition to the notifications top margin
  and some additional vertical space for aesthetics.
  */
  /*
  If disableBodyScroll is true (very uncommon use case) then the
  AppLayout component and not the document body will be the scrollable
  element. This requires a fixed height instead of a minimum height
  with a vertical scroll policy. The offset top value for components
  with position: sticky (AppBar, Navigation, Notifications, Tools) will
  be set zero since the AppLayout is nearest scrollable parent and any
  existing header does not matter. The offset top value for AppLayout
  contentMain children with position: sticky will be the value of the
  notifications height in addition to the notifications top margin
  and some additional vertical space for aesthetics.
  */
  /*
  In desktop viewports the content gap property for the left and right
  columns should override the default of zero if the navigationHide
  or toolsHide property is set. This ensures adequate horizontal space
  for the center column from the edges of the viewport when there is
  not left or right content.

  Warning! If these design tokens change it will adversely impact the
  calculation used to determine the Split Panel maximum width in the
  handleSplitPanelMaxWidth function in the context.
  */
  /*
  In mobile viewports the Navigation and Tools drawers are hidden
  and triggered by the AppBar which then take up the entire viewport.
  The center column needs permanent space from the horizontal viewport
  edges regardless of whether or not there is a Navigation or Tools.
  */
}
@media (min-width: 1401px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_has-max-content-width_hyvsj_1u1kg_600) {
    --awsui-default-max-content-width-3j0evk: 1280px;
  }
}
@media (min-width: 1921px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_has-max-content-width_hyvsj_1u1kg_600) {
    --awsui-default-max-content-width-3j0evk: 1440px;
  }
}
@media (min-width: 2541px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_has-max-content-width_hyvsj_1u1kg_600) {
    --awsui-default-max-content-width-3j0evk: 1620px;
  }
}
@media (min-width: 1401px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_has-max-content-width_hyvsj_1u1kg_600).awsui_content-type-dashboard_hyvsj_1u1kg_615 {
    --awsui-default-max-content-width-3j0evk: 1280px;
  }
}
@media (min-width: 1921px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_has-max-content-width_hyvsj_1u1kg_600).awsui_content-type-dashboard_hyvsj_1u1kg_615 {
    --awsui-default-max-content-width-3j0evk: 1620px;
  }
}
@media (min-width: 2541px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_has-max-content-width_hyvsj_1u1kg_600).awsui_content-type-dashboard_hyvsj_1u1kg_615 {
    --awsui-default-max-content-width-3j0evk: 2160px;
  }
}
.awsui_layout_hyvsj_1u1kg_517.awsui_has-max-content-width_hyvsj_1u1kg_600:not(#\9) {
  --awsui-default-max-content-width-3j0evk: var(--awsui-max-content-width-3j0evk);
}
@media (min-width: 993px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9) {
    --awsui-default-min-content-width-3j0evk: var(--awsui-min-content-width-3j0evk, 280px);
  }
}
@media (max-width: 992px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9) {
    --awsui-default-min-content-width-3j0evk: 0px;
  }
}
.awsui_layout_hyvsj_1u1kg_517.awsui_is-overlap-disabled_hyvsj_1u1kg_642:not(#\9) {
  --awsui-overlap-height-3j0evk: 0;
}
.awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_disable-body-scroll_hyvsj_1u1kg_645) {
  --awsui-offset-top-3j0evk: var(--awsui-header-height-3j0evk);
  min-height: var(--awsui-content-height-3j0evk);
}
.awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_disable-body-scroll_hyvsj_1u1kg_645):not(.awsui_has-sticky-notifications_hyvsj_1u1kg_649) {
  --awsui-offset-top-with-notifications-3j0evk: var(--awsui-header-height-3j0evk);
}
.awsui_layout_hyvsj_1u1kg_517:not(#\9):not(.awsui_disable-body-scroll_hyvsj_1u1kg_645).awsui_has-sticky-notifications_hyvsj_1u1kg_649 {
  --awsui-offset-top-with-notifications-3j0evk: calc(
      var(--awsui-header-height-3j0evk) + var(--awsui-notifications-height-3j0evk) + var(--space-xs-rsr2qu, 8px) + var(--space-xxxs-k2w98v, 2px)
    );
}
.awsui_layout_hyvsj_1u1kg_517.awsui_disable-body-scroll_hyvsj_1u1kg_645:not(#\9) {
  --awsui-offset-top-3j0evk: 0px;
  grid-template-rows: repeat(3, auto) var(--awsui-overlap-height-3j0evk) 1fr auto;
  height: var(--awsui-content-height-3j0evk);
  overflow-y: scroll;
}
.awsui_layout_hyvsj_1u1kg_517.awsui_disable-body-scroll_hyvsj_1u1kg_645.awsui_has-split-panel_hyvsj_1u1kg_663.awsui_split-panel-position-bottom_hyvsj_1u1kg_663:not(#\9) {
  grid-template-rows: repeat(3, auto) var(--awsui-overlap-height-3j0evk) repeat(2, auto);
}
.awsui_layout_hyvsj_1u1kg_517.awsui_disable-body-scroll_hyvsj_1u1kg_645:not(#\9):not(.awsui_has-sticky-notifications_hyvsj_1u1kg_649) {
  --awsui-offset-top-with-notifications-3j0evk: 0px;
}
.awsui_layout_hyvsj_1u1kg_517.awsui_disable-body-scroll_hyvsj_1u1kg_645.awsui_has-sticky-notifications_hyvsj_1u1kg_649:not(#\9) {
  --awsui-offset-top-with-notifications-3j0evk: calc(
      var(--awsui-notifications-height-3j0evk) + var(--space-xs-rsr2qu, 8px) + var(--space-xxs-ynfts5, 4px)
    );
}
@media (min-width: 689px) {
  .awsui_layout_hyvsj_1u1kg_517.awsui_has-content-gap-left_hyvsj_1u1kg_675:not(#\9) {
    --awsui-content-gap-left-3j0evk: var(--space-scaled-2x-xxxl-lbp35j, 40px);
  }
  .awsui_layout_hyvsj_1u1kg_517.awsui_has-content-gap-right_hyvsj_1u1kg_678:not(#\9) {
    --awsui-content-gap-right-3j0evk: var(--space-scaled-2x-xxxl-lbp35j, 40px);
  }
}
@media (max-width: 688px) {
  .awsui_layout_hyvsj_1u1kg_517:not(#\9) {
    --awsui-content-gap-left-3j0evk: var(--space-l-4vl6xu, 20px);
    --awsui-content-gap-right-3j0evk: var(--space-l-4vl6xu, 20px);
  }
}

/*
This CSS class is applied to the document body to prevent overflow scrolling
when the navigation or tools drawers are open in responsive viewports.
*/
.awsui_block-body-scroll_hyvsj_1u1kg_693:not(#\9) {
  overflow: hidden;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_container_hyvsj_1u1kg_701:not(#\9) {
  grid-column: 3;
  grid-row: 4/span 2;
  padding-bottom: var(--space-layout-content-bottom-kg9l42, 40px);
  /*
  If the split panel is in the bottom position additional padding will need to be
  added to the content area. This is to ensure that the user is able to scroll
  far enough to see all of the content that would otherwise be obscured by the
  sticky position of the split panel.
  */
  /*
  If disableContentPaddings is enabled then the Main content has a different
  behavior inside the Layout grid. By default it will render across the entire
  grid column span. If the Navigation is open on the left, we increment the
  start column by one. If the Tools or Split Panel (in side position) is open
  on the right, we decrement the column end by one.
  */
  /*
  If disableContentPaddings is not enabled (the majority of use cases) then
  the following code block will compute the padding behavior for the Main
  under various circumstances.
  */
}
.awsui_container_hyvsj_1u1kg_701.awsui_has-split-panel_hyvsj_1u1kg_663.awsui_split-panel-position-bottom_hyvsj_1u1kg_663:not(#\9) {
  padding-bottom: calc(var(--awsui-split-panel-height-3j0evk) + var(--space-layout-content-bottom-kg9l42, 40px));
}
.awsui_container_hyvsj_1u1kg_701.awsui_disable-content-paddings_hyvsj_1u1kg_727:not(#\9) {
  grid-column: 1/6;
  padding: 0;
}
@media (min-width: 689px) {
  .awsui_container_hyvsj_1u1kg_701.awsui_disable-content-paddings_hyvsj_1u1kg_727.awsui_is-navigation-open_hyvsj_1u1kg_732:not(#\9) {
    grid-column-start: 2;
  }
  .awsui_container_hyvsj_1u1kg_701.awsui_disable-content-paddings_hyvsj_1u1kg_727.awsui_is-tools-open_hyvsj_1u1kg_735:not(#\9), .awsui_container_hyvsj_1u1kg_701.awsui_disable-content-paddings_hyvsj_1u1kg_727.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_split-panel-position-side_hyvsj_1u1kg_735:not(#\9) {
    grid-column-end: 5;
  }
}
@media (min-width: 689px) {
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727) {
    /*
    Notifications is the previous adjacent sibling to the Main in
    desktop viewports if there are no Breadcrumbs or Header. Use additional
    to padding on the Main.
    */
    /*
    Main is the only rendered center content if there are no Notifications,
    Breadcrumbs, or Header. Use minimal top padding.
    */
    /*
    If the Header exists then it is necessarily the previous adjacent
    sibling to the Main which requires no top padding.
    */
  }
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-default_hyvsj_1u1kg_755.awsui_has-notifications-content_hyvsj_1u1kg_175:not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181), .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-form_hyvsj_1u1kg_755.awsui_has-notifications-content_hyvsj_1u1kg_175:not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181) {
    padding-top: var(--space-scaled-m-pv0fmt, 16px);
  }
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-default_hyvsj_1u1kg_755:not(.awsui_has-notifications-content_hyvsj_1u1kg_175):not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181), .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-form_hyvsj_1u1kg_755:not(.awsui_has-notifications-content_hyvsj_1u1kg_175):not(.awsui_has-breadcrumbs_hyvsj_1u1kg_167):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181) {
    padding-top: var(--space-scaled-xs-6859qs, 8px);
  }
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_has-header_hyvsj_1u1kg_181, .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181 {
    padding-top: 0;
  }
}
@media (max-width: 688px) {
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727) {
    /*
    If the Notifications exist but the Header does not then the
    Notifications is the previous adjacent sibling to the Main
    requiring additional top padding. This is because the Breadcrumbs
    are now rendered first and positioned sticky.
    */
    /*
    Main is the only rendered center content if there are no Notifications or Header.
    */
    /*
    If the Header exists then it is necessarily the previous adjacent
    sibling to the Main which requires no top padding.
    */
  }
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-default_hyvsj_1u1kg_755.awsui_has-notifications-content_hyvsj_1u1kg_175:not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181), .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-form_hyvsj_1u1kg_755.awsui_has-notifications-content_hyvsj_1u1kg_175:not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181) {
    padding-top: var(--space-scaled-m-pv0fmt, 16px);
  }
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-default_hyvsj_1u1kg_755:not(.awsui_has-notifications-content_hyvsj_1u1kg_175):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181), .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_content-type-form_hyvsj_1u1kg_755:not(.awsui_has-notifications-content_hyvsj_1u1kg_175):not(.awsui_has-header_hyvsj_1u1kg_181):not(.awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181) {
    padding-top: var(--space-scaled-m-pv0fmt, 16px);
  }
  .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_has-header_hyvsj_1u1kg_181, .awsui_container_hyvsj_1u1kg_701:not(#\9):not(.awsui_disable-content-paddings_hyvsj_1u1kg_727).awsui_has-dynamic-overlap-height_hyvsj_1u1kg_181 {
    padding-top: 0;
  }
}
.awsui_container_hyvsj_1u1kg_701.awsui_content-type-cards_hyvsj_1u1kg_185:not(#\9), .awsui_container_hyvsj_1u1kg_701.awsui_content-type-table_hyvsj_1u1kg_186:not(#\9), .awsui_container_hyvsj_1u1kg_701.awsui_content-type-wizard_hyvsj_1u1kg_182:not(#\9) {
  padding-top: 0;
}

/* stylelint-disable selector-max-universal, selector-combinator-disallowed-list */
.awsui_unfocusable_hyvsj_1u1kg_796:not(#\9),
.awsui_unfocusable_hyvsj_1u1kg_796 *:not(#\9) {
  visibility: hidden !important;
}

/* stylelint-enable selector-max-universal, selector-combinator-disallowed-list */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_navigation-container_hyvsj_1u1kg_806:not(#\9) {
  --awsui-navigation-width-3j0evk: 280px;
  display: flex;
  grid-column: 1;
  grid-row: 1/span 5;
  height: var(--awsui-content-height-3j0evk);
  position: sticky;
  top: var(--awsui-offset-top-3j0evk);
  z-index: 830;
  /*
  The navigation and tools containers (that contain the toggle buttons)
  stretch the full height of the app layout. Normally, this wouldn't be an
  issue because they sit above the app layout's content padding.

  But if disableContentPaddings is set to true and there are buttons on the
  left/right edges of the screen, they will be covered by the containers. So
  we need to disable pointer events in the container and re-enable them in
  the panels and toggle buttons.
  */
  pointer-events: none;
}
@media (min-width: 2541px) {
  .awsui_navigation-container_hyvsj_1u1kg_806:not(#\9) {
    --awsui-navigation-width-3j0evk: 320px;
  }
}
@media (max-width: 688px) {
  .awsui_navigation-container_hyvsj_1u1kg_806:not(#\9) {
    left: 0;
    position: fixed;
    top: var(--awsui-offset-top-3j0evk);
    z-index: 1001;
  }
}

nav.awsui_show-navigation_hyvsj_1u1kg_841:not(#\9) {
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-layout-toggle-padding-axl9jr, 20px);
  /*
  Apply the animation only in desktop viewports because the AppBar will
  take control in responsive viewports.
  */
}
@keyframes awsui_showButtons_hyvsj_1u1kg_1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
nav.awsui_show-navigation_hyvsj_1u1kg_841.awsui_is-navigation-open_hyvsj_1u1kg_732:not(#\9) {
  display: none;
}
@media (min-width: 689px) {
  nav.awsui_show-navigation_hyvsj_1u1kg_841:not(#\9):not(.awsui_is-navigation-open_hyvsj_1u1kg_732) {
    display: block;
  }
  nav.awsui_show-navigation_hyvsj_1u1kg_841:not(#\9):not(.awsui_is-navigation-open_hyvsj_1u1kg_732).awsui_animating_hyvsj_1u1kg_863 {
    animation: awsui_showButtons_hyvsj_1u1kg_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms);
  }
}
@media (min-width: 689px) and (prefers-reduced-motion: reduce) {
  nav.awsui_show-navigation_hyvsj_1u1kg_841:not(#\9):not(.awsui_is-navigation-open_hyvsj_1u1kg_732).awsui_animating_hyvsj_1u1kg_863 {
    animation: none;
    transition: none;
  }
}
@media (min-width: 689px) {
  .awsui-motion-disabled nav.awsui_show-navigation_hyvsj_1u1kg_841:not(#\9):not(.awsui_is-navigation-open_hyvsj_1u1kg_732).awsui_animating_hyvsj_1u1kg_863, .awsui-mode-entering nav.awsui_show-navigation_hyvsj_1u1kg_841:not(#\9):not(.awsui_is-navigation-open_hyvsj_1u1kg_732).awsui_animating_hyvsj_1u1kg_863 {
    animation: none;
    transition: none;
  }
}

nav.awsui_navigation_hyvsj_1u1kg_806:not(#\9) {
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  box-shadow: var(--shadow-panel-gp3bf2, 0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1));
  bottom: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  /*
  A non-semantic node is added with a fixed width equal to the final Navigation
  width. This will create the visual appearance of horizontal movement and
  prevent unwanted text wrapping.
  */
}
@keyframes awsui_openNavigation_hyvsj_1u1kg_1 {
  from {
    opacity: 0;
    width: calc(var(--space-layout-toggle-padding-axl9jr, 20px) * 2 + var(--space-layout-toggle-diameter-hzvqtj, 40px));
  }
  to {
    opacity: 1;
    width: var(--awsui-navigation-width-3j0evk);
  }
}
nav.awsui_navigation_hyvsj_1u1kg_806:not(#\9):not(.awsui_is-navigation-open_hyvsj_1u1kg_732) {
  width: 0;
  display: none;
}
nav.awsui_navigation_hyvsj_1u1kg_806.awsui_is-navigation-open_hyvsj_1u1kg_732.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: awsui_openNavigation_hyvsj_1u1kg_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  nav.awsui_navigation_hyvsj_1u1kg_806.awsui_is-navigation-open_hyvsj_1u1kg_732.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled nav.awsui_navigation_hyvsj_1u1kg_806.awsui_is-navigation-open_hyvsj_1u1kg_732.awsui_animating_hyvsj_1u1kg_863:not(#\9), .awsui-mode-entering nav.awsui_navigation_hyvsj_1u1kg_806.awsui_is-navigation-open_hyvsj_1u1kg_732.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: none;
  transition: none;
}
nav.awsui_navigation_hyvsj_1u1kg_806 > .awsui_animated-content_hyvsj_1u1kg_924:not(#\9) {
  width: var(--awsui-navigation-width-3j0evk);
}
@media (max-width: 688px) {
  nav.awsui_navigation_hyvsj_1u1kg_806:not(#\9) {
    --awsui-navigation-width-3j0evk: 100vw;
  }
}

.awsui_hide-navigation_hyvsj_1u1kg_933:not(#\9) {
  position: absolute;
  right: var(--space-m-17eucw, 16px);
  top: var(--size-vertical-panel-icon-offset-ti40n4, 15px);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_notifications_hyvsj_1u1kg_227:not(#\9) {
  grid-column: 3;
  grid-row: 1;
  z-index: 850;
  color: var(--color-text-body-default-ajf1h5, #000716);
  /*
  In desktop viewports the notifications will always be the first
  slot rendered in the center column. The padding should create
  a centered vertical alignment with the circular buttons for the
  navigation and tools drawers.
  */
  /*
  In mobile viewports the notifications should never be sticky even
  if the property is set. Padding is added to give adequate
  vertical space from the sticky AppBar the subsequent adjacent sibling.
  */
}
@media (min-width: 689px) {
  .awsui_notifications_hyvsj_1u1kg_227:not(#\9) {
    margin: var(--space-xs-rsr2qu, 8px) 0;
    /*
    var(--space-xs-rsr2qu, 8px) token needs to align with the $offsetTopWithNotifications
    calculation in the layout CSS.
    */
  }
  .awsui_notifications_hyvsj_1u1kg_227.awsui_sticky-notifications_hyvsj_1u1kg_240:not(#\9) {
    position: sticky;
    top: calc(var(--awsui-offset-top-3j0evk) + var(--space-xs-rsr2qu, 8px));
    --awsui-flashbar-sticky-bottom-margin-3j0evk: var(--space-xxl-2nvmf1, 32px);
  }
  .awsui_notifications_hyvsj_1u1kg_227:not(#\9):not(.awsui_has-notifications-content_hyvsj_1u1kg_175) {
    margin: 0;
  }
}
@media (max-width: 688px) {
  .awsui_notifications_hyvsj_1u1kg_227:not(#\9) {
    grid-row: 2;
    margin: var(--space-scaled-s-cu1hzn, 12px) 0;
  }
  .awsui_notifications_hyvsj_1u1kg_227.awsui_sticky-notifications_hyvsj_1u1kg_240:not(#\9) {
    position: static;
  }
  .awsui_notifications_hyvsj_1u1kg_227:not(#\9):not(.awsui_has-notifications-content_hyvsj_1u1kg_175) {
    margin: 0;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
When the Split Panel is in the bottom position it was share the same row
as the content area. This row is defined as 1 fractional unit which will
consume the remaining vertical space in the grid after the notifications
and breadcrumbs.
*/
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000:not(#\9) {
  /*
  The align self property will position the split panel at the bottom of the grid row.
  This could be off the viewport if the content area has enough content to be scrollable.
  */
  align-self: end;
  bottom: var(--awsui-footer-height-3j0evk);
  display: none;
  grid-column: 1/6;
  grid-row: 5;
  height: auto;
  /*
  The position sticky will work in conjunction with the align self: end; property.
  If the grid row scrolls beyond the viewport, the sticky bottom position
  will lift it up above the footer so it is always visible.
  */
  position: sticky;
  z-index: 840;
  /*
  Unlike the side position the Split Panel is persistent in the DOM
  when in the bottom position.
  */
  /*
  Warning! This is a hack! The existing design token for the split panel
  shadow in the bottom position does not render in the refactored code.
  It appears to be related to the fact that the legacy split panel element
  has a height equal to the expanded height and a corresponding translation
  of the Y position so it is moved off the screen. This will need to be
  refactored with an adjustment to the split panel design token.
  */
  /*
  When the data attribute changes indicating the Split Panel has been opened
  apply the animation to the height property.
  */
}
@keyframes awsui_openSplitPanelBottom_hyvsj_1u1kg_1 {
  from {
    height: var(--awsui-split-panel-reported-header-size-3j0evk, 0);
  }
  to {
    height: var(--awsui-split-panel-reported-size-3j0evk);
  }
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_disable-body-scroll_hyvsj_1u1kg_645:not(#\9) {
  bottom: 0;
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_is-navigation-open_hyvsj_1u1kg_732.awsui_position-bottom_hyvsj_1u1kg_1046:not(#\9) {
  grid-column-start: 2;
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_has-open-drawer_hyvsj_1u1kg_282.awsui_position-bottom_hyvsj_1u1kg_1046:not(#\9) {
  grid-column-end: 5;
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_position-bottom_hyvsj_1u1kg_1046:not(#\9) {
  display: block;
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000:not(#\9):not(.awsui_is-split-panel-open_hyvsj_1u1kg_735).awsui_position-bottom_hyvsj_1u1kg_1046 {
  box-shadow: rgba(0, 7, 22, 0.1) 0px -32px 32px -24px;
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-bottom_hyvsj_1u1kg_1046:not(#\9) {
  box-shadow: var(--shadow-split-bottom-otfetg, 0px -36px 36px -36px rgba(0, 7, 22, 0.1));
}
section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-bottom_hyvsj_1u1kg_1046.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: awsui_openSplitPanelBottom_hyvsj_1u1kg_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-bottom_hyvsj_1u1kg_1046.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-bottom_hyvsj_1u1kg_1046.awsui_animating_hyvsj_1u1kg_863:not(#\9), .awsui-mode-entering section.awsui_split-panel-bottom_hyvsj_1u1kg_1000.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-bottom_hyvsj_1u1kg_1046.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: none;
  transition: none;
}

section.awsui_split-panel-side_hyvsj_1u1kg_1075:not(#\9) {
  height: 100%;
  overflow-x: hidden;
  pointer-events: auto;
  /*
  The min and max widths are applied when the Split Panel is opened otherwise
  it would not be possible to animate the width and the box shadow would
  be persistent in the DOM when closed.
  */
  /*
  A non-semantic node is added with a fixed width equal to the final Split Panel
  width. This will create the visual appearance of horizontal movement and
  prevent unwanted text wrapping.
  */
}
@keyframes awsui_openSplitPanelSide_hyvsj_1u1kg_1 {
  from {
    opacity: 0;
    width: var(--awsui-split-panel-min-width-3j0evk, 280px);
  }
  to {
    opacity: 1;
    width: var(--awsui-split-panel-reported-size-3j0evk);
  }
}
section.awsui_split-panel-side_hyvsj_1u1kg_1075:not(#\9):not(.awsui_is-split-panel-open_hyvsj_1u1kg_735), section.awsui_split-panel-side_hyvsj_1u1kg_1075.awsui_position-bottom_hyvsj_1u1kg_1046:not(#\9) {
  width: 0;
}
section.awsui_split-panel-side_hyvsj_1u1kg_1075.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-side_hyvsj_1u1kg_1103:not(#\9) {
  box-shadow: var(--shadow-panel-gp3bf2, 0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1));
  max-width: var(--awsui-split-panel-max-width-3j0evk, 280px);
  min-width: var(--awsui-split-panel-min-width-3j0evk, 280px);
}
section.awsui_split-panel-side_hyvsj_1u1kg_1075.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-side_hyvsj_1u1kg_1103.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: awsui_openSplitPanelSide_hyvsj_1u1kg_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  section.awsui_split-panel-side_hyvsj_1u1kg_1075.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-side_hyvsj_1u1kg_1103.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled section.awsui_split-panel-side_hyvsj_1u1kg_1075.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-side_hyvsj_1u1kg_1103.awsui_animating_hyvsj_1u1kg_863:not(#\9), .awsui-mode-entering section.awsui_split-panel-side_hyvsj_1u1kg_1075.awsui_is-split-panel-open_hyvsj_1u1kg_735.awsui_position-side_hyvsj_1u1kg_1103.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: none;
  transition: none;
}
section.awsui_split-panel-side_hyvsj_1u1kg_1075 > .awsui_animated-content_hyvsj_1u1kg_924:not(#\9) {
  height: 100%;
  max-width: inherit;
  width: var(--awsui-split-panel-reported-size-3j0evk);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
The Tools component container has a max width calculation that depends on the
state of the other siblings within the Layout grid definition. The layout width
is set in the resize observer in the Layout component. The main offset left
will calculate the distance from the start of the Layout component. The minimum
content width has a default value that can be set directly with the minContentWidth
property. The content gap right is computed in the Layout styles based on the
viewport size and state of the Tools drawer.
*/
.awsui_tools-container_hyvsj_1u1kg_1140:not(#\9) {
  /* stylelint-disable scss/operator-no-newline-after */
  --awsui-tools-max-width-3j0evk: calc(var(--awsui-layout-width-3j0evk) - var(--awsui-main-offset-left-3j0evk) - var(--awsui-default-min-content-width-3j0evk) - var(--awsui-content-gap-right-3j0evk));
  /* stylelint-enable scss/operator-no-newline-after */
  --awsui-tools-width-3j0evk: 290px;
  display: flex;
  grid-column: 5;
  grid-row: 1/span 5;
  height: var(--awsui-content-height-3j0evk);
  max-width: var(--awsui-tools-max-width-3j0evk);
  position: sticky;
  top: var(--awsui-offset-top-3j0evk);
  z-index: 830;
  pointer-events: none;
}
@media (min-width: 2541px) {
  .awsui_tools-container_hyvsj_1u1kg_1140:not(#\9) {
    --awsui-tools-width-3j0evk: 360px;
  }
}
@media (max-width: 688px) {
  .awsui_tools-container_hyvsj_1u1kg_1140:not(#\9) {
    --awsui-tools-max-width-3j0evk: none;
    --awsui-tools-width-3j0evk: auto;
    position: fixed;
    right: 0;
    top: var(--awsui-offset-top-3j0evk);
    z-index: 1001;
  }
}

.awsui_tools_hyvsj_1u1kg_1140:not(#\9) {
  background-color: var(--color-background-container-content-i8i4a0, #ffffff);
  box-shadow: var(--shadow-panel-gp3bf2, 0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1));
  flex-shrink: 0;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior-y: contain;
  position: relative;
  word-wrap: break-word;
  pointer-events: auto;
  /*
  A non-semantic node is added with a fixed width equal to the final Tools
  width. This will create the visual appearance of horizontal movement and
  prevent unwanted text wrapping.
  */
  /*
  A right border is needed if the Tools is open and the buttons are persistent in
  the DOM. This creates a visual vertical boundary between the Tools and the Buttons
  only when they are both present. This is the circumstance when there is a Split Panel
  in the side position.
  */
}
@keyframes awsui_openTools_hyvsj_1u1kg_1 {
  from {
    opacity: var(--awsui-tools-animation-starting-opacity-3j0evk, 0);
    width: calc(var(--space-layout-toggle-padding-axl9jr, 20px) * 2 + var(--space-layout-toggle-diameter-hzvqtj, 40px));
  }
  to {
    opacity: 1;
    width: var(--awsui-tools-width-3j0evk);
  }
}
.awsui_tools_hyvsj_1u1kg_1140:not(#\9):not(.awsui_is-tools-open_hyvsj_1u1kg_735) {
  width: 0;
  display: none;
}
.awsui_tools_hyvsj_1u1kg_1140.awsui_is-tools-open_hyvsj_1u1kg_735.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: awsui_openTools_hyvsj_1u1kg_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_tools_hyvsj_1u1kg_1140.awsui_is-tools-open_hyvsj_1u1kg_735.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_tools_hyvsj_1u1kg_1140.awsui_is-tools-open_hyvsj_1u1kg_735.awsui_animating_hyvsj_1u1kg_863:not(#\9), .awsui-mode-entering .awsui_tools_hyvsj_1u1kg_1140.awsui_is-tools-open_hyvsj_1u1kg_735.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_tools_hyvsj_1u1kg_1140 > .awsui_animated-content_hyvsj_1u1kg_924:not(#\9) {
  width: var(--awsui-tools-width-3j0evk);
}
@media (min-width: 689px) {
  .awsui_tools_hyvsj_1u1kg_1140.awsui_is-tools-open_hyvsj_1u1kg_735.awsui_has-tools-form-persistence_hyvsj_1u1kg_1225:not(#\9) {
    border-right: var(--border-divider-section-width-4wm2it, 2px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
  }
}
@media (max-width: 688px) {
  .awsui_tools_hyvsj_1u1kg_1140:not(#\9) {
    --awsui-tools-width-3j0evk: 100vw;
  }
}

.awsui_hide-tools_hyvsj_1u1kg_1235:not(#\9) {
  position: absolute;
  right: var(--space-m-17eucw, 16px);
  top: var(--size-vertical-panel-icon-offset-ti40n4, 15px);
  z-index: 1;
}

/*
Warning! If these design tokens for padding change it will adversely impact
the calculation used to determine the Split Panel maximum width in the
handleSplitPanelMaxWidth function in the context.
*/
.awsui_show-tools_hyvsj_1u1kg_1247:not(#\9) {
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  border-collapse: separate;
  border-spacing: 0;
  caption-side: top;
  cursor: auto;
  direction: ltr;
  empty-cells: show;
  font-family: serif;
  font-size: medium;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  line-height: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: left;
  text-align-last: auto;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  widows: 2;
  word-spacing: normal;
  box-sizing: border-box;
  font-size: var(--font-body-m-size-sregvd, 14px);
  line-height: var(--font-body-m-line-height-i7xxvv, 22px);
  color: var(--color-text-body-default-ajf1h5, #000716);
  font-weight: 400;
  font-family: var(--font-family-base-qnistn, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
  padding: var(--space-scaled-s-cu1hzn, 12px) var(--space-layout-toggle-padding-axl9jr, 20px);
  /*
  Apply the animation only in desktop viewports because the AppBar will
  take control in responsive viewports.
  */
}
@keyframes awsui_showButtons_hyvsj_1u1kg_1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.awsui_show-tools_hyvsj_1u1kg_1247:not(#\9):not(.awsui_has-tools-form_hyvsj_1u1kg_1225) {
  display: none;
}
@media (min-width: 689px) {
  .awsui_show-tools_hyvsj_1u1kg_1247.awsui_has-tools-form_hyvsj_1u1kg_1225:not(#\9) {
    display: flex;
    flex-direction: column;
    gap: var(--space-xs-rsr2qu, 8px);
  }
  .awsui_show-tools_hyvsj_1u1kg_1247.awsui_has-tools-form_hyvsj_1u1kg_1225.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: awsui_showButtons_hyvsj_1u1kg_1 var(--motion-duration-refresh-only-fast-addxjd, 115ms);
  }
}
@media (min-width: 689px) and (prefers-reduced-motion: reduce) {
  .awsui_show-tools_hyvsj_1u1kg_1247.awsui_has-tools-form_hyvsj_1u1kg_1225.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: none;
    transition: none;
  }
}
@media (min-width: 689px) {
  .awsui-motion-disabled .awsui_show-tools_hyvsj_1u1kg_1247.awsui_has-tools-form_hyvsj_1u1kg_1225.awsui_animating_hyvsj_1u1kg_863:not(#\9), .awsui-mode-entering .awsui_show-tools_hyvsj_1u1kg_1247.awsui_has-tools-form_hyvsj_1u1kg_1225.awsui_animating_hyvsj_1u1kg_863:not(#\9) {
    animation: none;
    transition: none;
  }
  .awsui_show-tools_hyvsj_1u1kg_1247.awsui_has-tools-form-persistence_hyvsj_1u1kg_1225:not(#\9) {
    background-color: var(--color-background-container-content-i8i4a0, #ffffff);
    z-index: 1;
  }
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
Warning! If these design tokens for width change it will adversely impact 
the calculation used to determine the Split Panel maximum width in the 
handleSplitPanelMaxWidth function in the context.
*/
.awsui_trigger_hyvsj_1u1kg_1312:not(#\9) {
  background: var(--color-background-layout-toggle-default-f9hnkv, #192534);
  border: none;
  border-radius: 50%;
  box-shadow: var(--shadow-panel-toggle-3fk0ch, 0px 6px 12px 1px rgba(0, 7, 22, 0.12));
  color: var(--color-text-layout-toggle-20sb9r, #ffffff);
  cursor: pointer;
  height: var(--space-layout-toggle-diameter-hzvqtj, 40px);
  padding: 0 var(--space-s-hv8c1d, 12px);
  width: var(--space-layout-toggle-diameter-hzvqtj, 40px);
  pointer-events: auto;
}
body[data-awsui-focus-visible=true] .awsui_trigger_hyvsj_1u1kg_1312:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_trigger_hyvsj_1u1kg_1312:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(3px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_trigger_hyvsj_1u1kg_1312:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  left: calc(-1 * 3px);
  top: calc(-1 * 3px);
  width: calc(100% + 2 * 3px);
  height: calc(100% + 2 * 3px);
  border-radius: var(--border-radius-control-default-focus-ring-7661kz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-ap3b6s, #0972d3);
}
.awsui_trigger_hyvsj_1u1kg_1312:not(#\9):focus {
  outline: none;
}
.awsui_trigger_hyvsj_1u1kg_1312:not(#\9):hover {
  background: var(--color-background-layout-toggle-hover-gi0e0b, #354150);
}
.awsui_trigger_hyvsj_1u1kg_1312:not(#\9):active {
  background: var(--color-background-layout-toggle-active-d15dki, #354150);
}
.awsui_trigger_hyvsj_1u1kg_1312.awsui_selected_hyvsj_1u1kg_1351:not(#\9) {
  background: var(--color-background-layout-toggle-selected-default-e1giv3, #0972d3);
  color: var(--color-text-layout-toggle-selected-tzwifo, #ffffff);
}
.awsui_trigger_hyvsj_1u1kg_1312.awsui_selected_hyvsj_1u1kg_1351:not(#\9):hover {
  background: var(--color-background-layout-toggle-selected-hover-mes33s, #065299);
}
.awsui_trigger_hyvsj_1u1kg_1312.awsui_selected_hyvsj_1u1kg_1351:not(#\9):active {
  background: var(--color-background-layout-toggle-selected-active-9w7pk3, #0972d3);
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/