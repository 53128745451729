/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_lm6vo_1rqvf_97:not(#\9),
.awsui_layout-wrapper_lm6vo_1rqvf_98:not(#\9) {
  display: flex;
  flex-direction: column;
  position: relative;
  color: var(--color-text-body-default-ajf1h5, #000716);
}

.awsui_root-no-scroll_lm6vo_1rqvf_105:not(#\9) {
  position: relative;
  z-index: 1;
}

.awsui_layout_lm6vo_1rqvf_98:not(#\9) {
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
}
.awsui_layout-no-scroll_lm6vo_1rqvf_116:not(#\9) {
  overflow: hidden;
}

.awsui_layout-main_lm6vo_1rqvf_120:not(#\9) {
  flex: 1;
  min-width: 0;
  background-color: var(--color-background-layout-main-sfhm4y, #ffffff);
  position: relative;
}
.awsui_layout-main-scrollable_lm6vo_1rqvf_126:not(#\9) {
  overflow: auto;
}

/* stylelint-disable-next-line selector-combinator-disallowed-list, selector-max-universal */
.awsui_unfocusable_lm6vo_1rqvf_131 *:not(#\9) {
  visibility: hidden;
}

.awsui_breadcrumbs-desktop_lm6vo_1rqvf_135:not(#\9) {
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
  padding-bottom: var(--space-scaled-s-cu1hzn, 12px);
}

.awsui_content-header-wrapper_lm6vo_1rqvf_140:not(#\9) {
  padding-bottom: var(--space-content-header-padding-bottom-nvqytg, 24px);
}

.awsui_content-wrapper_lm6vo_1rqvf_144:not(#\9) {
  padding-bottom: var(--space-layout-content-bottom-kg9l42, 40px);
}

.awsui_content-overlapped_lm6vo_1rqvf_148:not(#\9) {
  margin-top: calc(-1 * var(--space-dark-header-overlap-distance-hfejul, 36px));
}

.awsui_content-extra-top-padding_lm6vo_1rqvf_152:not(#\9) {
  padding-top: var(--space-scaled-m-pv0fmt, 16px);
}