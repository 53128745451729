body {
  background-color: #EAEAEA;
}

.results {
  padding: 20px 20px 40px 20px;
  margin: 0 auto;

  div.filters {
    position: absolute !important;
    right: 0;
    z-index: 1;
    background-color: white;
    width: 300px;
  }

  div.categories-container {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    padding: 8px;
    border-radius: 16px;
    background-color: white;
    box-shadow: 1px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);
  }

  div.category {
    width: 100%;
    position: relative;

    & .title {
      min-height: 44px;
      font-size: 20px;
      font-weight: 400;
    }

    &>div.box {

      &>div.content {
        position: relative;
        border-radius: 16px;
        padding: 20px;
        text-align: center;
        color: black;
        box-shadow: 0px 4px 4px 0px #00000040;

        & progress {
          margin-right: 0 !important;
        }

        & span[class*="percentage"] {
          display: none !important;
        }
      }
    }
  }

  div.categories-arrow-container {
    display: flex;
    justify-content: space-evenly;

    & div {
      width: 100%;
      text-align: center;
      height: 31px;
    }
  }


  div.subcategories-container {
    display: flex;
    justify-content: space-between;
  }

  div.subcategory {
    background-color: white;
    height: fit-content;
    border-radius: 16px;
    width: 100%;
    position: relative;
    margin: 0 16px;
    padding: 8px;
    box-shadow: 1px 0px 1px 1px #e9ebed, 0px 1px 8px 2px rgba(0, 7, 22, 0.12);

    & .title {
      margin-top: 4px;
      min-height: 44px;
      font-size: 16px;
      font-weight: 400;
    }

    & .button {
      padding: 20px;
      border-radius: 20px;
      text-align: center;
      color: black;
      border: none;
      margin: 3px;
      box-shadow: 0px 4px 4px 0px #00000040;

      & progress {
        margin-right: 0 !important;
      }

      & span[class*="percentage"] {
        display: none !important;
      }
    }

    & .button.active {
      background-repeat: no-repeat;
      background-position: right top;
    }

    & .button.active:hover {
      margin: 0;
      transform: scale(1.05);
    }
  }

  div.progress {
    margin-top: 20px;
    border: 1px solid black;
    border-radius: 4px;
    height: 4px;
    background-color: white;

    & div.bar {
      background-color: #0972d3;
      height: 100%;
    }
  }

  span.badge {
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 12px;
    color: black;
    font-weight: 700;
    background-color: white;
  }

  .black {
    background-color: black !important;
    color: white !important;
    border-color: black !important;
  }

  .black :disabled,
  .black[disabled] {
    background-color: grey !important;
    color: white !important;
    border-color: grey !important;
  }

  .dashes {
    height: 20px;
    width: 50%;
    border-right: 2px dashed #BEBEBE;
  }
}