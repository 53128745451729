/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_notifications_1hmm4_1tp3h_9:not(#\9),
.awsui_notifications-sticky_1hmm4_1tp3h_10:not(#\9) {
  z-index: 850;
}

.awsui_notifications_1hmm4_1tp3h_9:not(#\9) {
  /* This is necessary for the z-index to have effect */
  position: relative;
}

.awsui_notifications-sticky_1hmm4_1tp3h_10:not(#\9) {
  top: 0;
  position: sticky;
  --awsui-flashbar-sticky-bottom-margin-3j0evk: var(--space-xxl-2nvmf1, 32px);
}

.awsui_no-content-paddings_1hmm4_1tp3h_25:not(#\9) {
  /*
  When using the disableContentPaddings option, the Flashbar will use this custom property to add additional space
  when the notification bar is rendered, to prevent it from overlapping the content.
   */
  --awsui-stacked-notifications-bottom-margin-3j0evk: var(--space-scaled-l-t03y3z, 20px);
}