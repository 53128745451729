/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_toggle_1uo6m_y69q2_97:not(#\9) {
  box-sizing: border-box;
  padding: var(--space-xxs-ynfts5, 4px) 7px;
}

.awsui_drawer-triggers_1uo6m_y69q2_102:not(#\9) {
  box-sizing: border-box;
}

.awsui_drawer_1uo6m_y69q2_102:not(#\9) {
  flex-shrink: 0;
  position: relative;
  word-wrap: break-word;
  box-shadow: var(--shadow-panel-gp3bf2, 0px 1px 1px 1px #e9ebed, 0px 6px 36px rgba(0, 7, 22, 0.1));
}
.awsui_drawer_1uo6m_y69q2_102:not(#\9):not(.awsui_drawer-mobile_1uo6m_y69q2_112) {
  z-index: 830;
}
.awsui_drawer-closed_1uo6m_y69q2_115:not(#\9) {
  min-width: 40px;
  cursor: pointer;
}
.awsui_drawer-closed_1uo6m_y69q2_115.awsui_drawer-mobile_1uo6m_y69q2_112:not(#\9) {
  display: none;
}

.awsui_drawer-content_1uo6m_y69q2_123:not(#\9) {
  position: fixed;
  overflow: auto;
  background-color: var(--color-background-layout-panel-content-808qum, #ffffff);
}
.awsui_drawer-mobile_1uo6m_y69q2_112 > .awsui_drawer-content_1uo6m_y69q2_123:not(#\9) {
  z-index: 1001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.awsui_drawer-closed_1uo6m_y69q2_115 > .awsui_drawer-content_1uo6m_y69q2_123:not(#\9) {
  width: 40px;
}
.awsui_drawer-closed_1uo6m_y69q2_115 > .awsui_drawer-content_1uo6m_y69q2_123:not(#\9):hover {
  background: var(--color-background-layout-panel-hover-0bg3gq, #e9ebed);
}
.awsui_drawer-closed_1uo6m_y69q2_115 > .awsui_drawer-content_1uo6m_y69q2_123.awsui_non-interactive_1uo6m_y69q2_141:not(#\9):hover {
  background: var(--color-background-layout-panel-content-808qum, #ffffff);
  cursor: default;
}
.awsui_drawer-content_1uo6m_y69q2_123 > [aria-hidden=true]:not(#\9) {
  display: none;
}

.awsui_drawer-triggers_1uo6m_y69q2_102 > .awsui_trigger_1uo6m_y69q2_149:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) 6px;
  margin: 1px 6px;
  border-radius: 0;
  border: 0;
}
.awsui_drawer-triggers_1uo6m_y69q2_102 > .awsui_trigger_1uo6m_y69q2_149:not(#\9):not(:last-child) {
  box-shadow: 0px 1px var(--color-shadow-layout-panel-trigger-t4yazw, #d1d5db);
}
.awsui_drawer-triggers_1uo6m_y69q2_102 > .awsui_trigger-drawer_1uo6m_y69q2_158:not(#\9):hover:not(.awsui_selected_1uo6m_y69q2_158) {
  color: var(--color-text-layout-panel-trigger-hover-yw2kb4, #0972d3);
}
.awsui_drawer-triggers_1uo6m_y69q2_102 > .awsui_trigger-drawer_1uo6m_y69q2_158:not(#\9):hover:not(.awsui_selected_1uo6m_y69q2_158):not(:last-child) {
  box-shadow: 0px 1px var(--color-shadow-layout-panel-trigger-t4yazw, #d1d5db);
}
.awsui_drawer-triggers_1uo6m_y69q2_102 > .awsui_trigger_1uo6m_y69q2_149.awsui_selected_1uo6m_y69q2_158:not(#\9) {
  padding: var(--space-scaled-xs-6859qs, 8px) var(--space-s-hv8c1d, 12px);
  margin: 0;
  border-top: 1px solid var(--color-background-layout-panel-trigger-active-uf4o0r, #00142b);
  border-bottom: 1px solid var(--color-background-layout-panel-trigger-active-uf4o0r, #00142b);
  background-color: var(--color-background-layout-panel-trigger-active-uf4o0r, #00142b);
  color: var(--color-text-layout-panel-trigger-active-mt4zu0, #ffffff);
  box-shadow: none;
}
.awsui_drawer-triggers_1uo6m_y69q2_102 > .awsui_trigger_1uo6m_y69q2_149.awsui_selected_1uo6m_y69q2_158:not(#\9):hover {
  cursor: default;
}