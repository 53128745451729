/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
@keyframes awsui_spinner-rotator_1612d_iivw8_1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes awsui_spinner-line-left_1612d_iivw8_1 {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(120deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes awsui_spinner-line-right_1612d_iivw8_1 {
  0% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(-30deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
.awsui_root_1612d_iivw8_127:not(#\9) {
  display: inline-block;
  vertical-align: top;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation: awsui_spinner-rotator_1612d_iivw8_1 0.7s linear infinite;
  box-sizing: border-box;
  line-height: 0;
}
.awsui_root_1612d_iivw8_127.awsui_size-normal_1612d_iivw8_135:not(#\9) {
  width: var(--size-icon-normal-j1z5fn, 16px);
  height: var(--size-icon-normal-j1z5fn, 16px);
  padding: calc((var(--size-icon-normal-j1z5fn, 16px) - 12px) / 2);
  margin-top: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-icon-normal-j1z5fn, 16px)) / 2);
  margin-bottom: calc((var(--font-body-m-line-height-i7xxvv, 22px) - var(--size-icon-normal-j1z5fn, 16px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_iivw8_127.awsui_size-big_1612d_iivw8_143:not(#\9) {
  width: var(--size-icon-big-cu55wt, 32px);
  height: var(--size-icon-big-cu55wt, 32px);
  padding: calc((var(--size-icon-big-cu55wt, 32px) - 24px) / 2);
  margin-top: calc((var(--font-heading-xl-line-height-doxfdj, 40px) - var(--size-icon-big-cu55wt, 32px)) / 2);
  margin-bottom: calc((var(--font-heading-xl-line-height-doxfdj, 40px) - var(--size-icon-big-cu55wt, 32px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_iivw8_127.awsui_size-large_1612d_iivw8_151:not(#\9) {
  width: var(--size-icon-large-9vh494, 48px);
  height: var(--size-icon-large-9vh494, 48px);
  padding: calc((var(--size-icon-large-9vh494, 48px) - 36px) / 2);
  margin-top: calc((var(--font-display-l-line-height-71tnj3, 54px) - var(--size-icon-large-9vh494, 48px)) / 2);
  margin-bottom: calc((var(--font-display-l-line-height-71tnj3, 54px) - var(--size-icon-large-9vh494, 48px)) / 2);
  box-sizing: border-box;
}
.awsui_root_1612d_iivw8_127.awsui_variant-normal_1612d_iivw8_159:not(#\9) {
  color: currentColor;
}
.awsui_root_1612d_iivw8_127.awsui_variant-disabled_1612d_iivw8_162:not(#\9) {
  color: var(--color-text-interactive-disabled-3pbb07, #9ba7b6);
}
.awsui_root_1612d_iivw8_127.awsui_variant-inverted_1612d_iivw8_165:not(#\9) {
  color: var(--color-text-inverted-821sko, #ffffff);
}

.awsui_circle_1612d_iivw8_169:not(#\9) {
  display: inline-block;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
.awsui_circle_1612d_iivw8_169:not(#\9):after {
  position: absolute;
  box-sizing: border-box;
  content: "";
  border-radius: 50%;
  border: 2px solid;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.awsui_circle_1612d_iivw8_169:not(#\9):after {
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation: 1.5s ease-in-out infinite;
  top: 0;
  left: 0;
  height: 100%;
  width: 200%;
}
.awsui_circle_1612d_iivw8_169.awsui_circle-left_1612d_iivw8_193:not(#\9):after {
  left: 0;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation-name: awsui_spinner-line-left_1612d_iivw8_1;
}
.awsui_circle_1612d_iivw8_169.awsui_circle-right_1612d_iivw8_198:not(#\9):after {
  left: -100%;
  /*stylelint-disable-next-line @cloudscape-design/no-motion-outside-of-mixin */
  animation-name: awsui_spinner-line-right_1612d_iivw8_1;
}