@import "./common/constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#firebaseui-auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -118px;
}


.global-spinner {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
}

img.profile {
  border: 1px solid #ccc;
  width: 96px;
  height: 96px;
}

.rmwc-avatar {
  border: 2px solid #ccc;
  margin-bottom: $spacing-small;
}

a[class^="awsui_header-link"] {
  display: block !important;
}

span[class^="awsui_header-link-text"] {
  display: block;
  margin-top: 16px;
  font-weight: 700 !important;
}

.awsui_overlap_hyvsj_1u1kg_246 {
  height: 74px; // Chipi Why?, why?, why?
}

div.scrim {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(35, 47, 62, 0.7);
}

.required {
  &::after {
    content: " *";
    color: red;
    font-weight: 900;
  }
}
